<template>
    <div class="enter-avtce">
        <el-upload
            class="avatar-uploader"
            action="/jeecg-boot/sys/common/upload"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
        >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <div class="mask">
                <i
                    class="el-icon-plus avatar-uploader-icon"
                    style="color: #6EB400"
                ></i>
            </div>
        </el-upload>
    </div>
</template>

<script>
import { Message } from "element-ui";
export default {
    props: ['picimg'],
    data() {
        return {
            headers: {
                "X-Access-Token": localStorage.getItem("token"),
            },
            imageUrl: "", // 头像显示
        };
    },
    watch: {
        picimg(val) {
            if (val) {
				this.imageUrl = "/jeecg-boot/" + val;
            }
        },
    },
    methods: {
        // 头像上传成功后的回调
        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.$emit("change", res.message);
                Message.success("图片上传成功");
            } else {
                Message.success("图片上传失败");
            }
        },
        beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
    }
    },
};
</script>

<style lang="scss" scoped>
::v-deep.enter-avtce{
	.avatar-uploader{
		.el-upload{
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 86px;
			height: 86px;
			line-height: 86px;
			&:hover{
				.mask {
					opacity: .5;
				}
			}
		}
	}
	.mask {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.3s;
		width: 86px;
		height: 86px;
		background-color: #eeeeee;
		border-radius: 3px;
		border: solid 1px #dbdbdb;
	}
	.avatar {
		width: 100%;
		height: 100%;
		display: block;
	}
}
</style>
